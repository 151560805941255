import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import Login from "./components/Login";
import Signup from "./components/Signup";
import AuthenticatedRequest from "./components/Authenticate";
import { AuthProvider, withRequiredAuthInfo } from "@propelauth/react"; // Import AuthProvider

function App() {
  return (
    <Router>
      <NavigationBar />
      <AuthenticatedRequest /> {/* Add this line */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/dashboard" element={withRequiredAuthInfo(Dashboard)} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={withRequiredAuthInfo(Profile)} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { fetchCars, deleteCar } from '../api'; // Import deleteCar API function
import { useAuthInfo } from '@propelauth/react';

function Dashboard() {
  const [cars, setCars] = useState([]);
  const { authToken } = useAuthInfo(); // Get the auth token

  useEffect(() => {
    const loadCars = async () => {
      try {
        const carsData = await fetchCars(authToken);
        setCars(carsData);
      } catch (error) {
        console.error('Failed to fetch cars', error);
      }
    };

    loadCars();
  }, [authToken]);

  // Function to handle car deletion
  const handleDeleteCar = async (carId) => {
    try {
      await deleteCar(carId, authToken); // Call delete API function
      setCars(cars.filter(car => car.id !== carId)); // Update state to remove the deleted car
    } catch (error) {
      console.error('Failed to delete car', error);
    }
  };

  return (
    <div>
      <h2>Dashboard</h2>
      <ul>
        {cars.map(car => (
          <li key={car.id}>
            {car.make} {car.model} ({car.year}) - ${car.price}
            <button onClick={() => handleDeleteCar(car.id)}>Delete</button> {/* Delete button */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Dashboard;
